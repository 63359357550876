import React from 'react'
import { Script } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'

import theme from './src/config/theme'
import Layout from './src/components/atoms/Layout'
import { GlobalStyle } from './src/styles/global-style'
import './src/config/icons'

const queryClient = new QueryClient()

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <Script id="___loader_script">
        {`
      document.getElementById("___loader").style.backdropFilter = "blur(8px) opacity(0)"
      document.getElementById("___loader").style.backgroundColor = "rgba(242, 242, 242, 0.1)"
      setTimeout(() => {
        document.getElementById("___loader").remove()
        document.getElementById("___loader_script").remove()
      }, 800)
      `}
      </Script>
      {/* <Script
      src={'https://www.googletagmanager.com/gtag/js?id=G-7XSKLG5B1M'}
      strategy="off-main-thread"
      forward={['gtag']}
    />
     <Script
        id="gtag-config"
        strategy="off-main-thread"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-7XSKLG5B1M', { send_page_view: false })`
        }}
      /> */}
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Layout>{element}</Layout>
      </ThemeProvider>
    </HelmetProvider>
  </QueryClientProvider>
)

export const wrapPageElement = ({ element }) => (
  <HelmetProvider>
    <Helmet>
      <link rel="stylesheet" href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css"
        integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=="
        crossOrigin="" />
      <script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      <script
        type="text/javascript"
        src="//client.crisp.chat/l.js"
        async
      />
    </Helmet>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>{element}</Layout>
    </ThemeProvider>
  </HelmetProvider>
)
