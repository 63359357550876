import reset from 'styled-reset'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    font-family: 'Source Sans Pro';
  }

  body {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
`
