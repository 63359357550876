import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCrosshairs, faSearch, faChevronRight, faChevronLeft, faTimes, faCircle,
  faTimesCircle, faBuilding, faKey, faMoneyBill, faShieldAlt, faSearchPlus,
  faEnvelope, faCheck, faListUl, faChevronUp, faChevronDown, faAngleUp, faPhoneAlt,
  faArrowRight, faMoneyBillWave, faNewspaper, faEuroSign, faLocationDot, faCircleCheck,
  faPen, faPlay, faStop, faUser, faEye, faEyeSlash, faBell
} from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle as farFaTimesCircle, faCircleCheck as farFaCircleCheck } from '@fortawesome/free-regular-svg-icons'

library.add(
  faCrosshairs, faSearch, faChevronRight, faChevronLeft, faTimes, faCircle,
  faTimesCircle, farFaTimesCircle, faBuilding, faKey, faMoneyBill, faShieldAlt,
  faSearchPlus, faEnvelope, faCheck, faListUl, faChevronUp, faChevronDown, faAngleUp,
  faPhoneAlt, faArrowRight, faPhoneAlt, faMoneyBillWave, faNewspaper, faEuroSign, faLocationDot,
  faCircleCheck, farFaCircleCheck, faPen, faPlay, faStop, faUser, faEye, faEyeSlash, faBell
)
