const theme = {
  red: '#FF0000',
  blue: '#2c71ed',
  black: '#000',
  white: '#fff',
  green: '#5fc34b',
  skyBlue: '#7fa4f6',
  paleGrey: '#f8fafd',
  paleBlue: '#eaf2ff',
  otherBlue: '#5c8bf4',
  mediumBlue: '#cedeff',
  lightOrange: '#ff9900',
  transparent: 'transparent',
  grey: '#adadad',
  stripeGrey: '#f3f3f3',
  stripeBorderGrey: '#dddddd',
  brownishGrey: '#707070',
  brightOrange: '#ffa733',
  veryLightOrange: '#fff9ef'
}

export default theme
