import { includes } from 'lodash'

import { wrapRootElement as wrapRoot, wrapPageElement as wrapPage } from './root-wrapper'

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined
  if (typeof gtag === 'function') {
    window.gtag('event', 'page_view', { page_path: pagePath })
  }
}

export const onClientEntry = () => {
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer')
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location }
}) => {
  const { pathname, href } = location

  if (includes(href, '#')) {
    return true
  }

  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = []

  // if the new route is not part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) === -1) {
    window.scrollTo(0, 0)
  }

  return false
}

export const wrapPageElement = wrapPage

export const wrapRootElement = wrapRoot
